import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { AuthMutationTypes } from './mutations';
import { UserMutationTypes } from '../user/mutations';
import { NotificationCenterActionTypes } from '../notificationCenter/actions';
import { AuthState } from './state';
import { app } from '@/main';
import { ROUTES } from "@/router/routes";
import { setLocale, setTimezone } from "@/services/i18n";
import { deleteToken } from "@/services/firebase";
import { UserActionTypes } from '@/store/modules/user/actions';
import { TO_NATIVE_APP_EVENTS } from '@/utils/native-app';

export const AuthActionTypes = {
  ACCEPT_COOKIES: '@auth/ACCEPT_COOKIES',
  SET_FRIEND_CODE: '@auth/SET_FRIEND_CODE',
  LOGIN: '@auth/LOGIN',
  LOGOUT: '@auth/LOGOUT',
  UPDATE_USER_DATA: '@auth/UPDATE_USER_DATA',
  UPDATE_FIREBASE_TOKEN: '@auth/UPDATE_FIREBASE_TOKEN',
  REMOVE_FIREBASE_TOKEN: '@auth/REMOVE_FIREBASE_TOKEN',
  SET_VIDEO_INTERSTITIAL_SEEN: '@auth/SET_VIDEO_INTERSTITIAL_SEEN',
  SET_ADS_BLOCKED_MODAL_SEEN: '@auth/SET_ADS_BLOCKED_MODAL_SEEN',
};

const actions: ActionTree<AuthState, State> = {
  [AuthMutationTypes.SET_FRIEND_CODE]({commit}, friendCode) {
    return new Promise(resolve => {
      commit(AuthMutationTypes.SET_FRIEND_CODE, friendCode);
      resolve(true);
    });
  },
  [AuthActionTypes.ACCEPT_COOKIES]({commit}) {
    return new Promise((resolve) => {
      commit(AuthMutationTypes.SET_ACCEPT_COOKIES, true);
      resolve(true);
    });
  },

  [AuthActionTypes.LOGIN]({commit, dispatch}, token) {
    app.$http.setAuthToken(token);
    commit(AuthMutationTypes.SET_TOKEN, token);

    return dispatch(AuthActionTypes.UPDATE_USER_DATA)
      .then(() => Promise.all([
        dispatch(UserActionTypes.UPDATE_USER_WALLET),
        dispatch(UserActionTypes.UPDATE_USER_GAMES),
        dispatch(UserActionTypes.UPDATE_MINIGAMES),
      ])
    );
  },

  [AuthActionTypes.LOGOUT]({commit, state, dispatch}, redirect = true) {
    return new Promise((resolve) => {
      const userLang = state.user?.lang;
      dispatch(AuthActionTypes.REMOVE_FIREBASE_TOKEN, {userId: state.user?.id, token: state?.firebaseToken});
      commit(AuthMutationTypes.SET_TOKEN, null);
      commit(AuthMutationTypes.SET_USER, {});
      commit(UserMutationTypes.SET_USER_WALLET, {});
      commit(UserMutationTypes.SET_USER_GAMES, []);
      commit(UserMutationTypes.SET_USER_CHAT_LAST_MESSAGES, null);
      commit(UserMutationTypes.SET_USER_CHAT_HAS_UNREAD_LAST_MESSAGES, false);
      commit(UserMutationTypes.SET_USER_CHAT_COMPETITIONS_LAST_MESSAGES, null);
      commit(UserMutationTypes.SET_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES, false);
      dispatch(NotificationCenterActionTypes.CLEAR_NOTIFICATION_CENTER);
      app.$http.removeAuthToken();
      app.sendLegacyNativeAppEvent(TO_NATIVE_APP_EVENTS.LOGOUT);
      if (redirect) {
        app.$router.push({name: ROUTES.home.name, params: {lang: userLang}}).catch(() => {/** nothing to do */
        });
      }
      resolve(true);
    });
  },

  [AuthActionTypes.UPDATE_USER_DATA]({commit}, updateLang = true) {
    return new Promise((resolve, reject) => {
      app.$http.user.getDataUser().then(({data}) => {
        const user = data.data;
        try {
          user.timezone = user.timezone ? JSON.parse(user.timezone) : {};
        } catch (e) {
          user.timezone = {};
        }
        commit(AuthMutationTypes.SET_USER, user);
        if (updateLang) {
          setLocale(user?.lang, app.$router);
        }
        setTimezone(user.timezone.value);
        resolve(true);
      }).catch(() => {
        app.$http.removeAuthToken();
        reject();
      });
    });
  },

  [AuthActionTypes.UPDATE_FIREBASE_TOKEN]({commit, state}, {userId, token}) {
    return new Promise((resolve) => {
      if (token !== state.firebaseToken) {
        app.$http.auth.postDeviceToken(userId, token)
          .then(() => commit(AuthMutationTypes.SET_FIREBASE_TOKEN, token))
          .catch(() => commit(AuthMutationTypes.SET_FIREBASE_TOKEN, null));
      }

      resolve(true);
    });
  },

  [AuthActionTypes.REMOVE_FIREBASE_TOKEN]({commit}, {userId, token}) {
    return new Promise((resolve) => {
      if (userId && token) {
        app.$http.auth.deleteDeviceToken(userId, token)
          .catch(() => {
            /** Nothing to do */
          });
      }
      commit(AuthMutationTypes.SET_FIREBASE_TOKEN, null);
      deleteToken().catch(() => {/** Nothing to do */
      });

      resolve(true);
    });
  },
  [AuthActionTypes.SET_VIDEO_INTERSTITIAL_SEEN]({commit}, videoInterstitialSeen) {
    return new Promise((resolve) => {
      commit(AuthMutationTypes.SET_VIDEO_INTERSTITIAL_SEEN, videoInterstitialSeen);
      resolve(true);
    });
  },
  [AuthActionTypes.SET_ADS_BLOCKED_MODAL_SEEN]({commit}, adsBlockedModalSeen) {
    return new Promise((resolve) => {
      commit(AuthMutationTypes.SET_ADS_BLOCKED_MODAL_SEEN, adsBlockedModalSeen);
      resolve(true);
    });
  },
};

export default actions;
