import { app } from '@/main';
import { AppActionTypes } from '@/store/modules/app/actions';
import { getAdmob, isIosNativeApp, onTrackingAuthorized, onTrackingRejected } from '@/utils/native-app';
import { ERROR_BANNER_RELOAD_TIMEOUT, loadInterstitialLastSeen } from '@/services/ads';
const getBannerAdUnitId = (): string => isIosNativeApp ? process.env.VUE_APP_NATIVE_APP_IOS_BANNER_UNIT_ID : process.env.VUE_APP_NATIVE_APP_ANDROID_BANNER_UNIT_ID;
const getInterstitialAdUnitId = (): string => isIosNativeApp ? process.env.VUE_APP_NATIVE_APP_IOS_INTERSTITIAL_UNIT_ID : process.env.VUE_APP_NATIVE_APP_ANDROID_INTERSTITIAL_UNIT_ID;
const getRewardedAdUnitId = (): string => isIosNativeApp ? process.env.VUE_APP_NATIVE_APP_IOS_REWARDED_UNIT_ID : process.env.VUE_APP_NATIVE_APP_ANDROID_REWARDED_UNIT_ID;

let consentForm: string = null;

export const isConsentFormObtained = async (): Promise<boolean> => {
  const { AdmobConsentStatus } = await getAdmob();

  return consentForm === AdmobConsentStatus.OBTAINED;
};
export const initNativeAppAds = async (): Promise<boolean> => {
  const { AdMob, AdmobConsentStatus } = await getAdmob();

  try {
    await AdMob.initialize();

    const consentInfo = await AdMob.requestConsentInfo();
    if (consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
      const {status} = await AdMob.showConsentForm();
      consentForm = status;
    } else {
      consentForm = AdmobConsentStatus.OBTAINED;
    }

    const trackingInfo = await AdMob.trackingAuthorizationStatus();
    if (trackingInfo.status === 'notDetermined') {
      await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (authorizationStatus.status === 'authorized') {
      onTrackingAuthorized();
    } else {
      onTrackingRejected();
    }

    app.$store.dispatch(AppActionTypes.SET_ADS_LOADED, true);
  } catch (e) {
    // Nothing to do
  }

  await initBanners();
  await initIntertitials();
  await initRewardeds();

  return true;
};

let reloadTimeout: NodeJS.Timeout = null;

const initBanners = async () => {
  const { AdMob, BannerAdPluginEvents } = await getAdmob();
  AdMob.addListener(BannerAdPluginEvents.SizeChanged, size => {
    app.$store.dispatch(AppActionTypes.SET_TOP_BANNER_HEIGHT, size.height);
  });

  AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    clearTimeout(reloadTimeout);
    reloadTimeout = null;

    if (!app.$store.getters.firstAdLoaded) {
      app.$store.dispatch(AppActionTypes.SET_FIRST_AD_LOADED, true);
    }
  });

  AdMob.addListener(BannerAdPluginEvents.FailedToLoad, () => {
    if (app.appVisible) {
      reloadTimeout = setTimeout(() => {
        reloadBannerNativeApp();
      }, ERROR_BANNER_RELOAD_TIMEOUT);
    }
  });

  await showBannerNativeApp();
};

let bannerCreated = false;

export const showBannerNativeApp = async (): Promise<void> => {
  const { AdMob, BannerAdPosition, BannerAdSize } = await getAdmob();
  if (!bannerCreated) {
    AdMob.showBanner({
      adId: getBannerAdUnitId(),
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: BannerAdPosition.TOP_CENTER,
    }).then(() =>{
      bannerCreated = true;
    }).catch(() => {
      bannerCreated = false;
    });
  } else {
    AdMob.resumeBanner();
  }
};

export const reloadBannerNativeApp = async (): Promise<void> => {
  await removeBannerNativeApp();
  await showBannerNativeApp();
};

export const hideBannerNativeApp = async (): Promise<void> => {
  const { AdMob } = await getAdmob();
  await AdMob.hideBanner().then(() => {
    app.$store.dispatch(AppActionTypes.SET_TOP_BANNER_HEIGHT, 0);
  });
};

export const removeBannerNativeApp = async (): Promise<void> => {
  if (bannerCreated) {
    const { AdMob } = await getAdmob();
    bannerCreated = false;
    app.$store.dispatch(AppActionTypes.SET_TOP_BANNER_HEIGHT, 0);
    await AdMob.removeBanner()
      .catch(() => {/** Nothing to do */});
  }
};

let resolveInterstitialFunction: (value: boolean) => void = null;

const onShowInterstitialResponse = (success: boolean) => {
  if (resolveInterstitialFunction) {
    resolveInterstitialFunction(success);
    resolveInterstitialFunction = null;
  }
};

const initIntertitials = async () => {
  const { AdMob, InterstitialAdPluginEvents } = await getAdmob();
  loadInterstitialLastSeen();

  AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
    onShowInterstitialResponse(true);
  });

  AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, () => {
    onShowInterstitialResponse(false);
  });

  AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, () => {
    onShowInterstitialResponse(false);
  });
};

export const showInterstitialNativeApp = async (): Promise<boolean> => {
  const { AdMob } = await getAdmob();

  await AdMob.prepareInterstitial({adId: getInterstitialAdUnitId()});

  return new Promise((resolve) => {
    onShowInterstitialResponse(false);
    resolveInterstitialFunction = resolve;
    AdMob.showInterstitial().catch(() => onShowInterstitialResponse(false));
  });
};

let resolveRewardedFunction: (value: boolean) => void;

const onShowRewardedResponse = (success: boolean) => {
  if (resolveRewardedFunction) {
    resolveRewardedFunction(success);
    resolveRewardedFunction = null;
  }
};

const initRewardeds = async () => {
  const { AdMob, RewardAdPluginEvents } = await getAdmob();

  AdMob.addListener(RewardAdPluginEvents.Dismissed, () => {
    onShowRewardedResponse(true);
  });

  AdMob.addListener(RewardAdPluginEvents.FailedToLoad, () => {
    onShowRewardedResponse(false);
  });

  AdMob.addListener(RewardAdPluginEvents.FailedToShow, () => {
    onShowRewardedResponse(false);
  });
};

export const showRewardedNativeApp = async (): Promise<boolean> => {
  const { AdMob } = await getAdmob();

  await AdMob.prepareRewardVideoAd({adId: getRewardedAdUnitId()});

  return new Promise((resolve) => {
    onShowRewardedResponse(false);
    resolveRewardedFunction = resolve;
    AdMob.showRewardVideoAd().catch(() => onShowRewardedResponse(false));
  });
};
