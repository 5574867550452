
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-theme",
    computed: {
      colors() {
        return this.$store.state.app?.theme?.colors || {};
      },
      ownStyle() {
        return this.$store.state.app?.theme?.ownStyle || '';
      },
      topBannerHeight(): string {
        return this.$store.getters.topBannerHeight > 0
          ? `${this.$store.getters.topBannerHeight + 1}px`
          : '0px';
      },
      bottomBannerHeight(): string {
        return this.$store.getters.bottomBannerHeight > 0
          ? `${this.$store.getters.bottomBannerHeight + 1}px`
          : '0px';
      },
    },
    methods: {
      updatePropertyValue(propertyName: string, value: string) {
        document.documentElement.style.setProperty(propertyName, value);
      },
    },
    watch: {
      topBannerHeight() {
        this.updatePropertyValue('--top-margin-banner', this.topBannerHeight);
      },
      bottomBannerHeight() {
        this.updatePropertyValue('--bottom-margin-banner', this.bottomBannerHeight);
      },
    },
  });
